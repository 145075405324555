// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-artist-jsx": () => import("../src/pages/artist.jsx" /* webpackChunkName: "component---src-pages-artist-jsx" */),
  "component---src-pages-index-jsx": () => import("../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-location-manager-jsx": () => import("../src/pages/locationManager.jsx" /* webpackChunkName: "component---src-pages-location-manager-jsx" */),
  "component---src-pages-manager-jsx": () => import("../src/pages/manager.jsx" /* webpackChunkName: "component---src-pages-manager-jsx" */),
  "component---src-pages-promoter-jsx": () => import("../src/pages/promoter.jsx" /* webpackChunkName: "component---src-pages-promoter-jsx" */)
}

